<template>
  <div>
    <b-card class="report-page">
      <div
        class="report-title mobile-View d-flex align-items-center justify-content-between"
      >
        <div class="d-flex">
          <h4 class="card-title">
            {{ $t("ReportsData.ReportsTitle") }}  
          </h4>
        </div>
        <div class="d-flex align-items-center search-view">
          <search
            v-model="searchFiled"
            :searchColumn="searchColumn"
            :operator="operator"
          ></search>
          <b-button
            @click="goBack"
            variant="outline-primary"
            size="sm"
            class="v2-back"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
          </b-button>
        </div>
      </div>
      <div class="category-list">
        <div
          class="category-list-child"
          v-for="category in items"
          :key="category.category"
        >
          <h5 class="transport-usage">{{ category.category }}</h5>
          <b-row>
            <b-col sm="4" v-for="(item, index) in category.list" :key="index">
              <b-card
                border-variant="primary"
                bg-variant="transparent"
                class="shadow-none with-padding cursor-pointer"
                @click="selectUnitAndGroup(item)"
              >
                <div class="d-flex justify-content-between align-items-start">
                  <div class="card-title text-primary">{{ item.title }}</div>
                  <div>
                    <Badge
                      :tags="item.sensors"
                      :showTextInBadge="Number(5)"
                      :event="{ id: 1 }"
                      :maxTagShow="Number(1)"
                    ></Badge>
                  </div>
                </div>
                <b-card-text> {{ item.description }} </b-card-text>
                <div class="d-flex justify-content-end">
                  <div
                    v-for="(file, index) in item.supported_files"
                    :key="index"
                  >
                    <ExlReportIcon class="report-icon" v-if="file == 'EXCEL'" />
                    <PdfReportIcon class="report-icon" v-if="file == 'PDF'" />
                    <DocReportIcon class="report-icon" v-if="file == 'DOC'" />
                    <ChartReportIcon
                      class="report-icon"
                      v-if="file == 'CHART'"
                    />
                    <VideoReportIcon class="report-icon" v-if="file == 'VID'" />
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <div
            class="load-more d-flex justify-content-end"
            v-if="category.pagination.total_records - category.list.length > 0"
            :click="getAllCategoryReports"
          >
            <b-button variant="primary" pill>
              {{ $t("ReportData.LoadMore") }}
              <b-badge variant="success" pill>
                {{ category.pagination.total_records - category.list.length }}
              </b-badge>
            </b-button>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAlert,
  BButton,
  BRow,
  BCol,
  BImg,
  BTable,
  BProgress,
  BPagination,
  VBTooltip,
  BCardText,
  BBadge
} from "bootstrap-vue";
import UnitService from "@/libs/api/unit-service";
import ReportService from "@/libs/api/report-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ChartReportIcon from "@/assets/images/new-icon/chart-report.svg";
import DocReportIcon from "@/assets/images/new-icon/doc.svg";
import ExlReportIcon from "@/assets/images/new-icon/exl.svg";
import PdfReportIcon from "@/assets/images/new-icon/pdf.svg";
import VideoReportIcon from "@/assets/images/new-icon/video-report.svg";
import Search from "../Common/search.vue";
import vSelect from "vue-select";
import Badge from "@/layouts/components/Badge.vue";
export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BRow,
    BCol,
    BImg,
    BTable,
    ChartReportIcon,
    DocReportIcon,
    ExlReportIcon,
    PdfReportIcon,
    VideoReportIcon,
    BProgress,
    BPagination,
    VBTooltip,
    Search,
    vSelect,
    BCardText,
    Badge,
    BBadge
  },
  data() {
    return {
      show: false,
      totalAlert: 0,
      currentPage: 1,
      activeClass: "active",
      items: [],
      totalReport: 0,
      option: ["10", "20", "30"],
      searchColumn: ["name"],
      searchFiled: {},
      operator: "ilike",
      sensorItem: ["GPS", "Fence"]
    };
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
    },
    searchFiled(newVal, oldVal) {
      this.filter = newVal;
      this.getAllReportCategories();
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "report-list"
      });
    },
    async getAllReportCategories() {
      try {
        this.show = true;
        const me = this;
        const requestData = {};
        if (this.filter) {
          requestData.filter = this.filter.value;
        }
        let response = await new ReportService().getAllReportCategories(
          requestData
        );
        this.show = false;
        if (response && response.data) {
          this.items = response.data.list || [];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    async getAllCategoryReports(item) {},

    async selectUnitAndGroup(item) {
      this.$router.push({
        name: "select-unit",
        query: { report_type: item.report_type }
      });
    }
  },
  handlePageChange(newPage) {
    this.updateQueryParam();
  },
  updateQueryParam() {
    const query = {
      ...this.$route.query,
      perPage: this.perPage.toString(),
      page: this.currentPage.toString()
    };
    if (this.filter && this.filter.value) {
      query.filter = this.filter.value;
    }
    this.$router.replace({ query }).catch(() => {});
  },
  mounted() {
    this.$nextTick(() => {
      const { page, filter } = this.$route.query;
      if (filter) {
        this.filter = filter;
      }
      if (page) {
        this.currentPage = parseInt(page);
      }
    });
    this.getAllReportCategories();
  }
};
</script>
<style lang="scss">
.dark-layout {
  .report-page .report-title {
    border-bottom: 1px solid #3b4253 !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.report-page {
  min-height: 70vh;
  .card-body {
    padding: 0;
  }
  .with-padding {
    margin-bottom: 15px;
    &:hover .card-body {
      background-color: var(--rgb-primary-12);
    }
    .card-body {
      padding: 1rem;
      .card-title.text-primary {
        min-height: 24px;
      }
      .card-text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        min-height: 22px;
      }
    }
  }
  .report-title {
    padding: 1.5rem !important;
    margin-bottom: 0;
    border-bottom: 1px solid var(--border-color);
    .card-title {
      margin-bottom: 0px;
    }
  }
  .search-filter {
    margin-right: 15px;
  }
  .category-list {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    .card-title {
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 75%;
    }
    .card-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .load-more {
    .btn {
      padding: 0 0 0 15px;
      border: 0px solid transparent;
      .badge {
        padding: 10px;
        margin-top: 2px;
        margin-right: 2px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
}
@media only screen and (max-width: 991px) {
  .mobile-View {
    flex-flow: wrap !important;
    .search-view {
      margin-top: 20px;
    }
  }
}
.report-title {
  .btn-sm {
    padding: 0.686rem 1rem !important;
    border-radius: 0.358rem;
  }
}
.category-list{
   max-height: calc(100vh - 250px) !important;
    overflow-y: auto;
}
</style>
